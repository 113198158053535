<!-- 智汇学堂-作业与考试-入口 -->
<template>
  <div
    :class="`container exam-container ${checked ? 'night' : ''}`"
    :style="`background:${checked ? '#000' : '#fff'}`"
  >
    <!-- 提示弹窗 -->
    <!-- <div v-if="!show" class="shadow">
      <div class="tip">
        <div class="left">
          往左滑动至下一题
          <img src="@/assets/img/guideHandleft.png" alt='' />
        </div>
        <div class="right">
          往右滑动至上一题
          <img src="@/assets/img/guideHandright.png" alt='' />
        </div>
      </div>
      <div class="know" @click="clickKnow">我知道了</div>
    </div> -->
    <!-- 次标题与答题卡 -->
    <!-- <icon-svg :iconClass="`${checked?'icon-shensemoshi-tishu3x':'icon-qiansemoshi-tishu3x'}`" className="icon-class-small"></icon-svg> -->
    <!-- <div class="subhead">
      <div>{{ paperSubName }}</div>
      <div
        class="submit-paper-wrap topics"
        style="color: #18a6f3"
        @click="topicShow"
      >
        <span style="font-size: 12px; color: #999"
          >{{ currentIndex + 1 }}/{{ examTotal }}</span
        >
      </div>
    </div> -->
    <!-- 题目 -->
    <div
      class="question"
      v-longpress
      :style="`padding-bottom:100px;background:${checked ? '#000' : '#fff'}`"
    >
      <questions
        ref="questions"
        :examList="examList"
        :currentIndex="currentIndex"
        :isExercises="!isExam"
        :selected="!paperType"
        :queType="'liveWork'"
        @question-result="questionResult"
        @question-index="questionIndex"
        @upload="handleUpload"
      ></questions>
    </div>
    <div v-if="currentIndex === this.examList.length - 1" class="lastExam">
      已经到最后一题啦！
    </div>
    <!-- 上下题按钮 -->
    <!-- <div class="btn"  :style="`background:${checked?'#000':'#fff'}`">
      <div class="prev" @click="prev">上一题</div>
      <div class="next" @click="next">下一题</div>
    </div> -->
    <div class="bottom" :style="`background:${checked ? '#000' : '#F9F9F9'}`">
      <!-- <template v-if="!isExam">
        <div></div>
      </template> -->
      <div class="answerSheetBtn" @click="topicShow">
        <img src="@/assets/img/answerSheet.png" alt='' srcset='' /><span
          >答题卡</span
        >
      </div>
    </div>
    <!-- 答题卡 -->
    <answer-sheet
      v-if="showAnswerSheet"
      :answerCardList="answerCardList"
      :sumed="sumed"
      :isExercises="!isExam"
      :paperType="paperType"
      :submitTime="submitTime"
      @submit="submitJob"
      @again="again"
      @close="topicHide"
      @to-current-exam="toCurrentExam"
    ></answer-sheet>
    <!-- 上传窗口 -->
    <uploadCommon ref="uploadCommon"
    @uploadfinish="uploadfinish"
    >
    </uploadCommon>
  </div>
</template>

<script>
import uploadCommon from '@/components/commonModule/upload/uploadCommon'
import { Toast, Dialog, Notify } from "vant";
import Questions from "@/components/course/examQuestions/questions";
import { getQueryString } from "@/assets/js/util.js";
import AnswerSheet from "./answerSheet";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import yxsSlider from "@/components/yxsSlider";
function addAttr(richText) {
  try {
    if (!richText) return '';
    richText = String(richText);
    if (!richText.trim()) return '';
    const divNode = document.createElement("div");
    divNode.innerHTML = richText;
    const pNodeList = divNode.getElementsByTagName("p");
    // debugger

    [].forEach.call(pNodeList, (pItem, index) => {
      if (pItem.children.length > 0) {
        // 去除<br>标签
        [].forEach.call(pItem.children, (child) => {
          if (child.tagName && child.tagName.toLocaleLowerCase() === "br") {
            pItem.removeChild(child);
          }
        });
      }

      if (
        pItem.childNodes.length === 1 &&
        pItem.firstChild.tagName &&
        pItem.firstChild.tagName.toLocaleLowerCase() === "img"
      ) {
        pItem.setAttribute("block-img", "true");
      }
    });
    return divNode.innerHTML;
  } catch (e) {
    console.error(e);
    return richText;
  }
}
function addPrefix(richText, prefix) {
  try {
    if (!richText) return '';
    richText = String(richText);
    if (!richText.trim()) return richText;
    const divNode = document.createElement("div");
    divNode.innerHTML = richText;
    const pNodeList = divNode.getElementsByTagName("p");
    const spanNode = document.createTextNode(prefix + '');
    if (pNodeList.length > 0) {
      // 富文本
      const pNode = pNodeList[0];
      if (pNode.firstChild) {
        pNode.insertBefore(spanNode, pNode.firstChild);
      } else {
        pNode.appendChild(spanNode);
      }
    } else {
      // 老题，非富文本
      divNode.insertBefore(spanNode, divNode.firstChild);
    }
    return divNode.innerHTML;
  } catch (e) {
    return richText;
  }
}
export default {
  components: {
    Questions,
    AnswerSheet,
    yxsSlider,
    uploadCommon
  },
  data() {
    return {
      time: 0, // 考试剩余时间
      submitTime: null, // 提交时间
      isExam: false, // ture 考试,false 练习题
      paperType: true, // ture 做题,false 查看解析
      paperSubName: '本地2测试', // 作业名称
      courseId: 0, // 考试id
      workId: 0, // 作业id
      studentExamId: 0, // 考试id
      richText:
        "<p>无</p><p><img src='https://file.zhihuischool.cn/f38e0707035243fbb28a5687a289cfaf.png' title='' alt=''/></p>",
      isWiating: false,
      showCountdown: false,
      show: false,
      isCollection: '',
      sumList: [],
      examList: [],
      answerCardList: [],
      examTotal: 0,
      currentExam: {},
      currentIndex: 0,
      showAnswerSheet: false,
      current: 1,
      size: 10,
      timeLeft: 60 * 1000,
      isClickToLast: false, // 是否点击题目到的最后一题
      lessonId: '', // 课时id
      chapterId: '',
      // courseId: '',
      resultId: '',
      needMarking: '',
      isOldVersion: false,
      correctShow: false, // 纠错弹窗
      radio: "0",
      message: '',
      fontSizeSheetShow: false,
      uploadValue: {},
      multipleList: [
        {
          multiple: 0.8,
          name: "小",
        },
        {
          multiple: 1,
          name: "标准",
          default: true,
        },
        {
          multiple: 1.5,
          name: "大",
        },
        {
          multiple: 2,
          name: "超大",
        }
      ],
      checked: false,
      showUploadSelect:false,
    };
  },
  beforeDestroy () {},
  watch: {
    '$store.state.isGetToken' (val) {
      let type = this.$store.state.deviceType
      if (val && type === 1) {
        // ios
        window.webkit.messageHandlers.getData.postMessage('liveWorkData')
        window.getLiveWorkData = this.getIosLiveWorkData
      } else if (val && type === 2) {
        // 安卓
        this.getLiveWorkData()
      } else {
        // sessionStorage.setItem('token', '596a31e3-1415-46e7-a4e6-85c2279dccc3')
        // sessionStorage.setItem('tenant_id', 172)
        // this.paperSubName = '本地测试'
        // this.workId = 130
        // // // 45710
        // // this.courseId = 38826
        // // this.isExam = false
        // this.paperType = true
        // // // false 解析
        // this.getPaper(this.workId, 1)
      }
    }
  },
  computed: {
    ...mapGetters({ userSetting: 'userSetting' }),
    sumed () {
      // let uniqueArr = this.answerCardList.filter(item => (item.rightFlag === 1 || item.rightFlag === '1' || item.rightFlag === '0' || item.rightFlag === 0))
      // return uniqueArr.length
      return this.currentIndex + 1
    },
  },
  mounted () {
    // document.querySelector('#app').style.overflow = 'hidden'
  },
  created () {
    // sessionStorage.setItem('token', '596a31e3-1415-46e7-a4e6-85c2279dccc3')
    // sessionStorage.setItem('tenant_id', 172)
    // this.paperSubName = '本地测试'
    // this.workId = 164
    // // // 45710
    // // this.courseId = 38826
    // // this.isExam = false
    // this.paperType = true
    // // // false 解析
    // this.getPaper(this.workId, 1)
  },
  methods: {
    ...mapActions(['changeUserSetting']),
    // *****************************智汇-新增开始
    // 安卓
    getLiveWorkData () {
      const data = JSON.parse(window.Android.getLiveWorkData())
      if (data.id) {
        this.workId = parseInt(data.id)
        this.getPaper(this.workId, 1)
      }
      if (data.title) {
        this.paperSubName = data.title
      }
      if (data.status || data.status === 0 || data.status === '0') {
        if (parseInt(data.status) === 0) {
          // 做题
          this.paperType = true
        } else if (data.status == 1 || data.status == 2) {
          // 预览
          this.paperType = false
          if (data.time) {
            this.submitTime = data.time
          }
        }
      }
    },
    // ios
    getIosLiveWorkData (data) {
      const data2 = JSON.parse(data)
      if (data2.liveWId) {
        this.workId = parseInt(data2.liveWId)
        this.getPaper(this.workId, 1)
      }
      if (data2.name) {
        this.paperSubName = data2.name
      }
      if (data2.type || data2.type === 0 || data2.type === '0') {
        if (parseInt(data2.type) === 0) {
          // 做题
          this.paperType = true
        } else if (parseInt(data2.type) === 1) {
          // 预览
          this.paperType = false
          if (data2.time) {
            this.submitTime = data2.time
          }
        }
      }
    },
    // 提交作业/考试
    submitJob () {
      let data = {
        // answerElapsedTime: 0,
        liveWorkRecordId: this.workId
      }
      this.$request.putLiveWorkSubmit(data)
        .then((res) => {
          Notify({ type: "success", message: "提交成功" })
          let type = this.$store.state.deviceType
          if (type === 1) {
            window.webkit.messageHandlers.getData.postMessage('close()')
          } else if (type === 2) {
            window.Android.Close()
          }
        }).catch(err => {
          Notify({ type: "danger", message: "提交失败" });
        })
    },
    // 重新答题作业
    again () {
      let workId = 14609;
      let data = {
        type: 2,
        workId: workId,
      };
      this.getPaper(this.workId, 2)
    },
    // 开始考试
    beginExam () {
      let data = {
        studentExamId: parseInt(this.workId)
      }
      let data2 = {
        studentExamId: this.workId
        // type: type
      }
      this.$request.putExamBegin(data).then((res) => {
        if (res.data.code === 0) {
          let remainTime = res.data.data.remainTime
          this.timeLeft = remainTime * 1000
          // this.getPaper(this.workId, 1)
          this.$request.getExamPaper(data2).then((res) => {
            this.initPaper(res)
          }).finally(() => {
            Toast.clear()
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 倒计时主动交卷
    submitPaper () {
      let data = {
        studentExamId: parseInt(this.workId)
      }
      console.log('交卷')
      // return ;
      Dialog.alert({
        title: '温馨提示',
        message: '考试结束，已自动提交试卷'
      })
      return
      // this.submitJob()
    },
    // 上传点击
    handleUpload(val) {
      console.log(val,'val')
      // this.showUploadSelect = !this.showUploadSelect
      this.uploadValue = val
      if (val.uploadType == 'imgClick') {
        if (this.paperType) {
          this.$refs.uploadCommon.actions = this.$refs.uploadCommon.uploadedActions
        } else {
          this.$refs.uploadCommon.actions = this.$refs.uploadCommon.onlyReadActions
        }
        this.$refs.uploadCommon.isVideo = false
        if (this.uploadValue.miniNum) {
          this.$refs.uploadCommon.previewList = this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls
        } else {
          this.$refs.uploadCommon.previewList = this.examList[this.uploadValue.num - 1].selfImgUrls
        }
      } else if (val.uploadType == 'videoClick') {
        if (this.paperType) {
          this.$refs.uploadCommon.actions = this.$refs.uploadCommon.uploadedActions
        } else {
          this.$refs.uploadCommon.actions = this.$refs.uploadCommon.onlyReadActions
        }
        this.$refs.uploadCommon.videoInfo.id = val.id
        this.$refs.uploadCommon.videoInfo.source = val.selfVideoUrl
        // this.$refs.uploadCommon.isVideo = true
        this.$set(this.$refs.uploadCommon,'isVideo',true)
      } else {
        if (val.typeId == 6) {
          if (this.uploadValue.miniNum) {
            this.$refs.uploadCommon.previewList = this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls || []
          } else {
            this.$refs.uploadCommon.previewList = this.examList[this.uploadValue.num - 1].selfImgUrls || []
          }
          this.$refs.uploadCommon.actions = this.$refs.uploadCommon.uploadingBothActions
        } else {
          this.$refs.uploadCommon.actions = this.$refs.uploadCommon.uploadingActions
        }
      }
      this.$refs.uploadCommon.showAction = !this.$refs.uploadCommon.showAction
    },
    // 上传选择结束
    uploadfinish (obj) {
      console.log(obj,'obj2')
      console.log(this.uploadValue,'uploadValue')
      let imgUrl = []
      let videoUrl = ''
      // if (this.uploadValue.childrenList.length>0) {

      // } else {
      // }
      // 只分问答或填空
      if (obj && obj.type && obj.type == 'video') {
        // 视频上传
        console.log('video');
        videoUrl = this.examList[this.uploadValue.num - 1].selfVideoUrl
        videoUrl = obj.excelUrl
      } else if (this.uploadValue.uploadType == 'img') {
        if (this.uploadValue.fillNum || this.uploadValue.fillNum===0) {
          // 填空
          if (this.uploadValue.miniNum || this.uploadValue.miniNum==0) {
            // 大题下填空
            if (this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls) {
              // 存在过图片上传 同步数组
              imgUrl = this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls
            }
            console.log('大题上传',imgUrl);
          } else {
            if (this.examList[this.uploadValue.num - 1].selfImgUrls) {
              // 存在过图片上传 同步数组
              imgUrl = this.examList[this.uploadValue.num - 1].selfImgUrls
            }
          }
          if (this.uploadValue.fillIdx <= imgUrl.length - 1) {
            imgUrl[this.uploadValue.fillIdx] = obj.excelUrl
          } else {
            imgUrl.push(obj.excelUrl)
          }
        } else {
          // 问答
          if (this.uploadValue.miniNum || this.uploadValue.miniNum==0) {
            if (this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls && this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls.length>0) {
              // 初始化 数组
              imgUrl = this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls
            }
          } else {
            if (this.examList[this.uploadValue.num - 1].selfImgUrls && this.examList[this.uploadValue.num - 1].selfImgUrls.length>0) {
              // 初始化 数组
              imgUrl = this.examList[this.uploadValue.num - 1].selfImgUrls
            }
          }
          // if (obj.length > 0) {
          //   for (let i in obj) {
          //     imgUrl.push(obj[i].excelUrl)
          //   }
          // } else {
          // }
          imgUrl.push(obj.excelUrl)
        }
      } else if (obj=='delImg') {
        if (this.uploadValue.miniNum || this.uploadValue.miniNum==0) {
          imgUrl = this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls
        } else {
          imgUrl = this.examList[this.uploadValue.num - 1].selfImgUrls
        }
        if (this.uploadValue.typeId == 5) {
          imgUrl[this.uploadValue.fillIdx] = ''
        } else if (this.uploadValue.typeId == 6) {
          if (imgUrl&&imgUrl.length>0) {
            imgUrl.splice(this.uploadValue.fillIdx, 1)
          }
        }
      }
      let que = {
        id: this.uploadValue.id,
        selfAnswer: this.uploadValue.selfAnswer,
        selfImgUrls: imgUrl ? imgUrl.join(',') : imgUrl,
        selfVideoUrl: videoUrl
      }
      this.saveRecord(que).then((res) => {
        console.log(res,'上传保存成功')
        if ((obj.length==1 && obj[0].type && obj[0].type == 'video')|| obj=='delVideo') {
          if (this.uploadValue.miniNum || this.uploadValue.miniNum==0) {
            this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfVideoUrl = JSON.parse(JSON.stringify(videoUrl))
            this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls = JSON.parse(JSON.stringify(imgUrl))
          } else {
            this.examList[this.uploadValue.num - 1].selfVideoUrl = JSON.parse(JSON.stringify(videoUrl))
            this.examList[this.uploadValue.num - 1].selfImgUrls = JSON.parse(JSON.stringify(imgUrl))
          }
          console.log(videoUrl, 'videoUrl')
        } else if (this.uploadValue.uploadType == 'img'|| obj=='delImg') {
          if (this.uploadValue.miniNum || this.uploadValue.miniNum==0) {
            // 大题
            this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls = JSON.parse(JSON.stringify(imgUrl))
            this.examList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfVideoUrl = JSON.parse(JSON.stringify(videoUrl))
            this.answerCardList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfImgUrls = JSON.parse(JSON.stringify(imgUrl))
            this.answerCardList[this.uploadValue.num - 1].childrenList[this.uploadValue.miniNum].selfVideoUrl = JSON.parse(JSON.stringify(videoUrl))
          } else {
            this.examList[this.uploadValue.num - 1].selfImgUrls = JSON.parse(JSON.stringify(imgUrl))
            this.examList[this.uploadValue.num - 1].selfVideoUrl = JSON.parse(JSON.stringify(videoUrl))
            this.answerCardList[this.uploadValue.num - 1].selfImgUrls = JSON.parse(JSON.stringify(imgUrl))
            this.answerCardList[this.uploadValue.num - 1].selfVideoUrl = JSON.parse(JSON.stringify(videoUrl))
          }
          // this.$set(this.examList[this.uploadValue.num - 1],'selfImgUrls',imgUrl)
          console.log(imgUrl, 'imgUrl', videoUrl)
        }
      })
    },
    // *****************************智汇-新增结束
    wyChange () {
      // console.log(11111);
      // window.addEventListener('resize', () => {
      // if (document.activeElement.tagName == 'INPUT') {
      //   window.setTimeout(() => {
      //     console.log(555)
      //     document.activeElement.scrollIntoViewIfNeeded()
      //     console.log(666)
      //   }, 500)
      // }
      // })
      // document.querySelector('.wybc').scrollIntoView()
    },
    // // 夜间模式切换
    // changeMode (mode) {
    //   var str = ''
    //   if (mode) {
    //     str = 'night'
    //     // window.location.href = 'sendmessage/setcolormodeexam?mode=night'
    //   } else {
    //     str = 'day'
    //     // window.location.href = 'sendmessage/setcolormodeexam?mode=day'
    //   }
    //   this.changeUserSetting({ 'colorModeExam': str })
    //   this.examList = this.examList.map(ele => {
    //     if (ele.typeId === 7) {
    //       return {
    //         ...ele,
    //         colorMode: str,
    //         childrenList: ele.childrenList.map(item => ({
    //           ...item,
    //           colorMode: str
    //         }))
    //       }
    //     }
    //     return {
    //       ...ele,
    //       colorMode: str
    //     }
    //   })
    // },
    // onProgressChange (multipeInfo) {
    //   console.log(multipeInfo)
    //   this.changeUserSetting({ 'fontMultipleExam': multipeInfo.multiple })
    //   this.examList = this.examList.map(ele => {
    //     if (ele.typeId === 7) {
    //       return {
    //         ...ele,
    //         fontSizeMultiple: multipeInfo.multiple,
    //         childrenList: ele.childrenList.map(item => ({
    //           ...item,
    //           fontSizeMultiple: multipeInfo.multiple
    //         }))
    //       }
    //     }
    //     return {
    //       ...ele,
    //       fontSizeMultiple: multipeInfo.multiple
    //     }
    //   })
    // },
    /**
     * 获取答题卡列表
     */
    getPaper (workId, type) {
      Toast.loading({
        duration: 0,
        message: "题目生成中",
      })
      let data = {
        liveWorkRecordId: workId,
        // courseId: this.courseId,
        // type: type
      }
      this.$request
        .getLiveWorkQList(data)
        .then((res) => {
          this.initPaper(res)
        })
        .finally(() => {
          Toast.clear()
        })
    },
    // 题目初始化
    initPaper (res) {
      // console.log(res.data.data,'res0');
      if (res.data.code !== 0) {
        return
      }
      // if (Date.parse(new Date(res.data.examResult.endTime)) - Date.parse(new Date(res.data.examResult.updateTime)) < 0) {
      //   // TODO: 处理考试已结束的情况
      // }

      // if (!res.data.examResult.updateTime) {
      //   res.data.examResult.updateTime = res.data.examResult.createTime // 如果 updateTime 为空，便用createTime计算考试倒计时
      // }

      // // 考试剩余时间
      // this.timeLeft = Date.parse(new Date(res.data.examResult.endTime.replace(/-/g, '/'))) - Date.parse(new Date(res.data.examResult.updateTime.replace(/-/g, '/')))

      // this.showCountdown = true
      // this.$nextTick(() => {
      //   this.$refs['count-down'].start() // 开始计时
      // })

      res.data.data = res.data.data.map((question, childrenIndex) => {
        if (
          question.typeId === 1 ||
          question.typeId === 2 ||
          question.typeId === 3
        ) {
          // 单选，多选，判断
          return {
            ...question,
            optionContents: JSON.stringify(
              JSON.parse(question.optionsContent).map((optionItem) => ({
                ...optionItem,
                content: addAttr(optionItem.content),
              }))
            ),
            // stem: addAttr(
            //   addPrefix(question.stem, childrenIndex + 1 + ".")
            // ),
            analysis: addAttr(question.analysis),
            fontSizeMultiple: this.userSetting.fontMultipleExam
              ? this.userSetting.fontMultipleExam
              : 1,
            colorMode: this.userSetting.colorModeExam
              ? this.userSetting.colorModeExam
              : "day",
          };
        } else {
          // 除选择其他题型
          return {
            ...question,
            // stem: addAttr(
            //   addPrefix(question.stem, childrenIndex + 1 + ".")
            // ),
            selfImgUrls: question.selfImgUrls ? question.selfImgUrls.split(',') : question.selfImgUrls,
            analysis: addAttr(question.analysis),
            fontSizeMultiple: this.userSetting.fontMultipleExam
              ? this.userSetting.fontMultipleExam
              : 1,
            colorMode: this.userSetting.colorModeExam
              ? this.userSetting.colorModeExam
              : "day",
          };
        }
      });
      // console.log(res.data.data, 'res1');
      // 大题数据处理
      res.data.data = res.data.data.map((question) => {
        if (
          (question.typeId === 7 || question.typeId === 8) &&
            question.childrenList &&
            question.childrenList.length > 0
        ) {
          return {
            ...question,
            selfAnswer: question.childrenList.some(
              (item) => item.selfAnswer
            ),
            childrenList: question.childrenList.map(
              (childrenItem, childrenIndex) => {
                if (
                  childrenItem.typeId === 1 ||
                  childrenItem.typeId === 2 ||
                  childrenItem.typeId === 3
                ) {
                  // 单选，多选，判断
                  return {
                    ...childrenItem,
                    optionContents: JSON.stringify(
                      JSON.parse(childrenItem.optionsContent).map(
                        (optionItem) => ({
                          ...optionItem,
                          content: addAttr(optionItem.content),
                        })
                      )
                    ),
                    // stem: addAttr(
                    //   addPrefix(
                    //     childrenItem.stem,
                    //     "（" + (childrenIndex + 1) + "）"
                    //   )
                    // ),
                    analysis: addAttr(childrenItem.analysis),
                    fontSizeMultiple: this.userSetting.fontMultipleExam
                      ? this.userSetting.fontMultipleExam
                      : 1,
                    colorMode: this.userSetting.colorModeExam
                      ? this.userSetting.colorModeExam
                      : "day",
                  };
                } else {
                  return {
                    ...childrenItem,
                    // stem: addAttr(
                    //   addPrefix(
                    //     childrenItem.stem,
                    //     "（" + (childrenIndex + 1) + "）"
                    //   )
                    // ),
                    selfImgUrls: childrenItem.selfImgUrls ? childrenItem.selfImgUrls.split(',') : childrenItem.selfImgUrls,
                    analysis: addAttr(childrenItem.analysis),
                    fontSizeMultiple: this.userSetting.fontMultipleExam
                      ? this.userSetting.fontMultipleExam
                      : 1,
                    colorMode: this.userSetting.colorModeExam
                      ? this.userSetting.colorModeExam
                      : "day",
                  };
                }
              }
            ),
          };
        }
        return {
          ...question,
          childrenList: [],
        };
      });

      // console.log(res.data.data, "<><<<<");

      if (_.isArray(res.data.data) && res.data.data.length > 0) {
        // TODO: 处理试卷没有考题的情况
      }

      if (res.data.data.every((item) => item.answer)) {
        // 题目全部答完了
        // TODO: 处理试题全部做完了的情况
      }

      const lastQuestionIndex = _.findIndex(
        res.data.data,
        (item) => item.lastFlag === 1 || item.lastFlag === '1'
      )

      this.$refs.questions.$refs.swiper.swipeTo(lastQuestionIndex)
      // console.log('cardList', _.cloneDeep(res.data))
      this.answerCardList = res.data.data
      this.examList = res.data.data
      // console.log(this.examList, 'examlist0')
      this.examTotal = this.answerCardList.length
      // this.resultId = res.data.examResult.id
      this.needMarking = res.data.needMarking
      this.init()
      // if (type === 2) {
      //   this.toCurrentExam(1)
      // }
    },
    xx() {},
    clickKnow() {
      this.show = true;
      // 引导页载入缓存
      window.localStorage.setItem("tipShow", true);
    },
    // 选择、作答回调
    questionResult (val) {
      console.log(val, 'val')
      let valQ = val
      if (val.typeId === 7 || val.typeId === 8) {
        valQ = val.childrenList[val.miniNum]
      }
      let data = {
        ...valQ,
        selfImgUrls: valQ.selfImgUrls ? (valQ.selfImgUrls).toString() : valQ.selfImgUrls
      }
      this.saveRecord(data).then((res) => {
        console.log(val, 'valQ2')
        let list = this.examList
        this.answerCardList = list
        if (val.typeId === 7 || val.typeId === 8) {
          // console.log(this.examList[this.currentIndex].childrenList[val.miniNum].selfAnswer, 'val.selfAnswer11')
          console.log(val.selfAnswer, 'val.selfAnswer1', val.num)
          this.examList[val.num - 1].childrenList[val.miniNum].selfAnswer = JSON.parse(JSON.stringify(valQ.selfAnswer))
          let childList = this.examList[val.num - 1].childrenList
          this.examList[val.num - 1].childrenList = JSON.parse(JSON.stringify(childList))

          this.answerCardList[val.num - 1].childrenList[val.miniNum].selfAnswer = JSON.parse(JSON.stringify(valQ.selfAnswer))
          let cardList = this.examList[val.num - 1].childrenList
          this.answerCardList[val.num - 1].childrenList = JSON.parse(JSON.stringify(cardList))
          // this.$set(this.answerCardList[val.num - 1].childrenList[val.miniNum], 'selfAnswer', valQ.selfAnswer)
        } else {
          console.log(val.selfAnswer, 'val.selfAnswer2', val.num)
          this.examList[val.num - 1].selfAnswer = JSON.parse(JSON.stringify(val.selfAnswer))
          this.answerCardList[val.num - 1].selfAnswer = JSON.parse(JSON.stringify(val.selfAnswer))
          // this.$set(this.answerCardList[val.num - 1], 'selfAnswer', val.selfAnswer)
        }
        console.log(this.examList)
        console.log(this.answerCardList)
        // 最后一题
        if (this.currentIndex === this.examList.length - 1) {
          if (val.parentQuestion && (val.parentQuestion.typeId === 7 || val.parentQuestion.typeId === 8)) {
            let flag = false
            if (val.parentQuestion && val.parentQuestion.childrenList.length > 0) {
              let num = 0
              val.childrenList.forEach(item => {
                if (item.selfAnswer !== '' || item.selfAnswer !== null) {
                  // ++num
                  flag = true
                  // if (num === val.childrenList.length) {
                  // }
                }
                // console.log(flag, 'flag', num)
              })
            }
            if (!flag) {
              Toast({
                message: "已经到最后一题啦！",
                duration: 1000
              })
            }
          } else {
            Toast({
              message: "已经到最后一题啦！",
              duration: 1000
            })
          }
        }
        if (val.typeId === 1 || val.typeId === 4) {
          if (val.parentQuestion) {
            // console.log('有小题')
          } else {
            // console.log('无小题')
            this.$nextTick((_) => {
              this.$refs.questions.$refs.swiper.next();
            });
          }
        }
      });
      return
      if (
        (val.parentQuestion && val.parentQuestion.typeId == 7) ||
        (val.parentQuestion && val.parentQuestion.typeId == 8)
      ) {
        console.log("进入了配伍题提交");
        console.log(val)
        this.saveRecord(val)
          .then((res) => {
            this.examList[this.currentIndex].selfAnswer = true
            this.answerCardList = this.examList
            return this.saveRecord({ ...val.parentQuestion, answer: true })
          })
          .then((res) => {
            console.log(res, "<<<<<")
          });
      } else {
        console.log("非7 8");
        this.saveRecord(val).then((res) => {
          this.examList[this.currentIndex].selfAnswer = val.selfAnswer
          this.answerCardList = this.examList
          if (this.currentIndex === this.examList.length - 1) {
            Toast({
              message: "已经到最后一题啦！",
              duration: 1000
            });
          }
          if (val.typeId === 1 || val.typeId === 4) {
            this.$nextTick((_) => {
              this.$refs.questions.$refs.swiper.next();
            });
          }
        });
      }
      // 练习题保存答案
    },
    questionIndex(val) {
      this.currentIndex = val;
      this.init();
    },
    init() {
      this.currentExam = this.examList[this.currentIndex];
      // console.log(this.currentExam, "9999");
      this.isCollection =
        this.currentExam.collectionStatus === 1 ||
        this.currentExam.collectionStatus === "1";
    },
    saveRecord (question) {
      return this.$request.putLiveWorkAnswer({
        id: question.id,
        selfAnswer: question.selfAnswer,
        selfImgUrls: question.selfImgUrls,
        selfVideoUrl: question.selfVideoUrl
      })
    },
    // 上一题
    prev() {
      if (this.currentIndex === 0) {
        Toast({
          message: "已经到第一道题啦!",
          duration: 1000,
        });
        return;
      }
      this.$nextTick((_) => {
        this.$refs.questions.$refs.swiper.prev();
      });
    },
    // 下一题
    next() {
      if (this.currentIndex === this.examList.length - 1) {
        Toast({
          message: "已经到最后一题啦！",
          duration: 1000,
        });
      }
      this.$nextTick((_) => {
        this.$refs.questions.$refs.swiper.next();
      });
    },
    topicShow() {
      this.showAnswerSheet = true;
    },
    topicHide() {
      this.showAnswerSheet = false;
    },
    // 跳转到指定题目
    toCurrentExam(index) {
      this.isClickToLast = true;
      this.$nextTick((_) => {
        this.$refs.questions.$refs.swiper.swipeTo(index);
        this.topicHide();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.van-dialog.night {
  background: #404040;
  .van-dialog__header {
    color: #ddd;
  }
  .van-dialog__message {
    color: #ddd;
  }
  .van-button {
    background: #404040;
    color: #ddd;
    &.van-dialog__confirm {
      color: #1989fa;
    }
  }
}
.van-cell:not(:last-child)::after {
  border-bottom: none !important;
}
.exam-container {
  .van-swipe-item {
    background: #fff;
  }
  &.night {
    .van-swipe-item {
      background: #000;
    }
  }
  //   .fontSheet.van-action-sheet{
  //       height: 173px;
  //   }
}
</style>
<style lang="scss" scoped>
.uploadCommon {
  // height: 100vh;
  // position: fixed;
  // width: 100vw;
  // top: 0;
}
.icon-class-small {
  width: 18px !important;
  height: 18px !important;
}
.topics {
  font-size: 12px;
  color: #333;
  span {
    color: #999;
  }
  svg {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto 5px;
  }
}
.exam-container {
  ::v-deep .content-action-sheet {
    // height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;
    .nightBox {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
      .nightMode {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(61, 61, 61, 1);
      }
    }
    .wzsf {
      height: 18px;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(61, 61, 61, 1);
      line-height: 18px;
      padding: 15px 0;
    }
  }
}
.exam-countdown {
  padding: 5px;
  text-align: center;
}
.container {
  width: 100%;
  height: 100vh;
  background: #fff;
  padding: 0;
}
.shadow {
  width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.4);
  z-index: 11;
  .tip {
    padding: 255px 15px 0;
    overflow: hidden;
    color: #eee;
    .left {
      float: left;
      img {
        display: block;
        width: 42px;
        height: 60px;
        padding-top: 22px;
        margin: 0 auto;
      }
    }
    .right {
      float: right;
      img {
        display: block;
        width: 38px;
        height: 60px;
        padding-top: 22px;
        margin: 0 auto;
      }
    }
  }
  .know {
    width: 127px;
    line-height: 40px;
    margin: 83px auto 0;
    background: #fff;
    color: #18a6f3;
    text-align: center;
    border: 1px solid #18a6f3;
    border-radius: 20px;
  }
}
.subhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.4rem 0;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #363840;
}
.lastExam {
  margin-top: 50px;
  width: 100%;
  font-size: 15px;
  color: #c6c6c6;
  text-align: center;
}
.bt-collect {
  text-align: center;
}
.bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: space-around;
  // width: 348px;
  height: 32px;
  padding: 20px 0;
  box-shadow: 0 2px 4px 0 rgba($color: #000000, $alpha: 0.2);
  background: #fff;
  align-items: center;
  .collect {
    width: 48px;
    font-size: 12px;
    color: #333;
    text-align: center;
    img {
      display: block;
      width: 14px;
      height: 13px;
      margin: 0 auto 5px;
    }
  }
  .answerSheetBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 12px;
    }
  }
  .submit {
    font-size: 12px;
    color: #18a6f3;
    img {
      display: block;
      width: 14px;
      height: 14px;
      margin: 0 auto 5px;
    }
  }
  .submit-paper-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .submit-paper {
    font-size: 13px;
  }
  .topics {
    font-size: 0.3rem;
    color: #363840;
  }
}
</style>
<style lang="scss">
.exam-container {
  .van-count-down {
    color: #E21A1A;
    display: inline-block;
    // height: 22px;
    // box-sizing: border-box;
    // color: #aaa;
    // font-size: 12px;
    // border-top-right-radius: 10px;
    // border-bottom-right-radius: 10px;
    // display: inline-block;
    // padding: 0 10px;
    // margin-top: 8px;
  }
}
.van-dialog__header {
  padding-top: 8px;
}
.btn {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 58px;
  width: 100%;
  z-index: 3;
  background-color: #fff;
  padding: 3px 0;
  .prev {
    // width: 80px;
    // line-height: 30px;
    // font-size: 13px;
    // color: #18A6F3;
    // text-align: center;
    // border: 1px solid #18A6F3;
    // border-radius: 17px;
    // box-sizing: border-box;
    width: 108px;
    height: 34px;
    border-radius: 6px;
    border: 1px solid rgba(23, 125, 219, 1);
    text-align: center;
    font-size: 13px;
    box-sizing: border-box;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(23, 125, 219, 1);
    line-height: 34px;
    margin-right: -80px;
  }
  .next {
    // width: 80px;
    // line-height: 30px;
    // font-size: 13px;
    // color: #fff;
    // text-align: center;
    // background: #18A6F3;
    // border-radius: 17px;
    width: 108px;
    height: 34px;
    background: rgba(23, 125, 219, 1);
    border-radius: 6px;
    text-align: center;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(247, 248, 249, 1);
    line-height: 34px;
  }
}
.correctType {
  height: 20px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(138, 138, 142, 1);
  line-height: 20px;
  margin-top: 20px;
  padding-left: 20px;
  margin-bottom: 15px;
}
.content .van-radio__label {
  word-break: break-all;
}
.correctPop {
  &.night {
    background: #404040;
    .van-radio__label {
      color: #ddd;
    }
    .van-field__control {
      background: #ccc;
    }
  }
  &.day {
    background: #fff;
  }
  .van-radio:nth-child(1),
  .van-radio:nth-child(3) {
    width: 48%;
    height: 40px;
    margin-left: 20px;
    line-height: 40px;
  }
  .van-radio:nth-child(2),
  .van-radio:nth-child(4) {
    height: 40px;
    line-height: 40px;
  }
  .van-field__control {
    background: rgba(247, 247, 247, 1);
    border-radius: 16px;
    text-indent: 20px;
  }
  .submit {
    width: 170px;
    height: 36px;
    background: rgba(24, 166, 243, 1);
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    line-height: 36px;
    margin-left: calc(50% - 85px);
    text-align: center;
    margin-top: 5px;
  }
}
</style>
