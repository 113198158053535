<!--
 * 答题卡
 -->
 <template>
  <div class="modal" @click.self="close">
    <div class="answer-sheet" :style="`background:${userSetting.colorModeExam=='day'?'':'#fff'}`">
      <!-- <div class="topics">
        <img src="@/assets/images/topic.png" alt="">
        <span class="num">{{ sumed }}</span>/{{ examTotal }}
      </div> -->
      <!-- <div class="topics">
        <icon-svg :iconClass="`${userSetting.colorModeExam=='night'?'icon-shensemoshi-tishu3x':'icon-qiansemoshi-tishu3x'}`" className="icon-class"></icon-svg>
        <span :style="`color:${userSetting.colorModeExam=='day'?'#333':'#ddd'}`">{{ sumed }}/{{ examTotal }}</span>
      </div> -->
      <div class="sheetHead">
        <div>答题卡 <span class="time" v-if="!paperType">提交作业时间：{{$way.timestampReturnDate(submitTime, 'timeYMDHM')}}</span></div>
        <van-icon name="cross" @click.self="close"/>
      </div>
      <div :class="{'topics1':true,'night':userSetting.colorModeExam=='night'}">
        <div class="answer" :style="`color:${userSetting.colorModeExam=='day'?'#333':'#ddd'}`">
          <span class="answered"></span>已答 {{ answeredSum() }} 道
        </div>
        <div class="answer" :style="`color:${userSetting.colorModeExam=='day'?'#333':'#ddd'}`">
          <span></span>未答 {{ answerCardList.length - answeredSum() }} 道
        </div>
        <div class="clear" @click="submit" v-show="false">
          <img src="@/assets/img/paper.png" alt="">交卷
        </div>
      </div>
      <div class="t-items">
        <div class="item-box">
          <div class="t-item" :class="{ 'answered': changeStatus(item), 'none': !changeStatus(item) }" v-for="(item, index) in answerCardList" :key="index" @click="toCurrentExam(index)">
            {{ index + 1 }}
          </div>
        </div>
      </div>
      <div class="sheetBtn" v-if="paperType">
        <!-- <van-button type="default" v-if="isExercises" @click="again">重新答题</van-button> -->
        <van-button plain type="primary" @click="submit">{{!isExercises ? '交卷':'提交'}}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    submitTime: {
      type: String,
      default: function () {
        return null
      }
    },
    sumed: {
      type: Number,
      default: function () {
        return 0
      }
    },
    // answeredSum: { // 已答
    //   type: Number,
    //   default: function () {
    //     return 0
    //   }
    // },
    leftSum: { // 未答
      type: Number,
      default: function () {
        return 0
      }
    },
    answerCardList: {
      type: Array,
      default: function () {
        return []
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    paperType: {
      type: Boolean,
      default: function () {
        return true
      }
    }
  },
  data () {
    return {
      examTotal: this.answerCardList.length
    }
  },
  created () {
    console.log(this.userSetting.colorModeExam, 111)
    console.log(this.answerCardList, 'answerCardList')
  },
  watch: {
    answerCardList: {
      deep: true,
      handler (newV, oldV) {
        console.log(newV, 'newsheet')
        console.log(oldV, 'oldsheet')
        this.answerCardList = newV
        console.log(this.answerCardList, 'ansCL')
      }
    }
  },
  computed: {
    ...mapGetters({ userSetting: 'userSetting' }),
    answerStatus () {
      return function (item) {
        const userAnswer = item.userAnswer
        if (this.isExercises) {
          return userAnswer ? ((item.rightFlag === 1 || item.rightFlag === '1') ? '' : 'error') : 'none'
        } else {
          if (userAnswer) return 'answered'
          return userAnswer ? 'answered' : 'none'
        }
      }
    }
  },
  methods: {
    // 已答数量
    answeredSum () {
      let num = 0
      this.answerCardList.forEach(item => {
        if (item.typeId < 7) {
          if (item.selfAnswer && item.selfAnswer !== '[]' && item.selfAnswer !== []) {
            if (item.typeId == 6) {
              if (item.selfAnswer.length > 0) {
                num++
              }
            } else {
              num++
            }
          } else {
            if (item.typeId==6) {
              if (item.selfImgUrls) {
                ++num
              } else if (item.selfVideoUrl) {
                ++num
              }
            } else if (item.typeId==5) {
              if (item.selfImgUrls) {
                ++num
              }
            }
          }
        } else if (item.childrenList.length > 0) {
          let num2 = 0
          item.childrenList.forEach(item2 => {
            if (item2.selfAnswer && item2.selfAnswer !== '[]' && item2.selfAnswer !== []) {
              if (item2.typeId == 6) {
                if (item2.selfAnswer.length > 0) {
                  num2++
                }
              } else {
                num2++
              }
            } else {
              if (item2.typeId==6) {
                if (item2.selfImgUrls) {
                  ++num2
                } else if (item2.selfVideoUrl) {
                  ++num2
                }
              } else if (item2.typeId==5) {
                if (item2.selfImgUrls) {
                  ++num2
                }
              }
            }
          })
          if (num2 === item.childrenList.length) {
            num++
          }
        }
      });
      return num
    },
    // leftSum () {
    //   let num = this.answeredSum
    //   return this.answerCardList.length - num
    //   // return this.answerCardList.filter((item) => !item.selfAnswer).length
    // },

    // 改变已填未填状态
    changeStatus (item) {
      // item.selfAnswer
      if (item.childrenList.length > 0) {
        // console.log('有小题2')
        // console.log(item,'item');
        let flag = false
        let num = 0
        for (let i in item.childrenList) {
          if (item.childrenList[i].selfAnswer !== [] && item.childrenList[i].selfAnswer !== null) {
            // console.log('大题有答案', i)
            console.log(item.childrenList[i].selfAnswer,i)
            if (item.childrenList[i].typeId === 6) {
              if (item.childrenList[i].selfAnswer.length > 0) {
                ++num
              }
            } else if (item.childrenList[i].typeId === 2) {
              if (item.childrenList[i].selfAnswer !== [] && item.childrenList[i].selfAnswer !== null && item.childrenList[i].selfAnswer !== '[]') {
                ++num
              }
            } else {
              ++num
            }
            if (num === item.childrenList.length) {
              flag = true
              item.selfAnswer = true
            }
          } else {
            if (item.childrenList[i].typeId === 5) {
              if (item.childrenList[i].selfImgUrls) {
                console.log('88888');
                ++num
              }
            } else if (item.childrenList[i].typeId === 6) {
              if (item.childrenList[i].selfImgUrls) {
                ++num
              } else if (item.childrenList[i].selfVideoUrl) {
                ++num
              }
            }
            if (num === item.childrenList.length) {
              flag = true
              item.selfAnswer = true
            }
          }
        }
        return flag
      } else {
        // 非大题
        if (item.typeId === 6) {
          if (item.selfAnswer !== null && item.selfAnswer.length > 0) {
            return true
          }
          if (item.selfImgUrls) {
            for (let i in item.selfImgUrls) {
              if (item.selfImgUrls[i]) {
                return true
              }
            }
          }
          if (item.selfVideoUrl) {
            return true
          }
        } else if (item.typeId === 5) {
          console.log(JSON.parse(item.selfAnswer))
          let self = JSON.parse(item.selfAnswer)
          if (item.selfAnswer !== null && self.length > 0) {
            for (let i in self) {
              if (self[i].length > 0) {
                return true
              }
            }
          }
          if (item.selfImgUrls) {
            console.log('item.selfImgUrls',item.selfImgUrls);
            for (let i in item.selfImgUrls) {
              if (item.selfImgUrls[i]) {
                return true
              }
            }
          }
        } else if (item.typeId === 2) {
          // console.log(item.selfAnswer, 'sea')
          if (item.selfAnswer !== null && item.selfAnswer !== [] && item.selfAnswer !== '[]') {
            return item.selfAnswer
          }
        } else {
          return item.selfAnswer
        }
      }
    },
    again () {
      this.$emit('again')
    },
    submit () {
      this.$emit('submit')
    },
    close () {
      this.$emit('close')
    },
    toCurrentExam (index) {
      this.$emit('to-current-exam', index)
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal {
    width: 100%;
    height: 100vh;
    background: rgba($color: #000000, $alpha: .2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
  }
  .answer-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: max-content;
    background: #fff;
    box-sizing: border-box;
    border-radius: 20px 20px 0px 0px;
    padding-top: 24px;
    .sheetHead{
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #363840;
      .time {
        color: #999999;
    font-size: 14px;
      }
    }
    .topics {
      padding: 15px;
      font-size: 13px;
      color: #999;
      img {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 7px;
      }
      .num {
        color: #333;
      }
      .reback {
        display: inline-block;
        color: #7AB703;
        margin-left: 23px;
      }
      .setting {
        width: 18px;
        height: 18px;
        float: right;
      }
    }
    .topics1 {
      padding: 8px 15px 18px 15px;
      font-size: 13px;
      color: #333;
      border-bottom: 1px solid #eaeaea;
      &.night{
          border-bottom: 1px solid #2c2c2c;
      }
      overflow: hidden;
      .answer {
        float: left;
        margin-right: 26px;
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 8px;
          border: 1px solid #c6c6c6;
          border-radius: 2px;
          box-sizing: border-box;
        }
        img {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 8px;
          border-radius: 2px;
          box-sizing: border-box;
        }
        .answered {
          border: 1px solid #18A6F3;
        }
      }
      .clear {
        float: right;
        display: flex;
        align-items: center;
        img {
          display: inline-block;
          margin-right: 7px;
          width: 12px;
          height: 12px;
        }
      }
    }
    .t-items {
      padding: 0 15px;
      overflow-y: auto;
      height: 310px;
      .item-box {
        display: flex;
        flex-flow: wrap;
        padding-bottom: 50px;
        .t-item {
          margin: 14px 15px 0 0;
          width: 32px;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          color: #333;
          &.night{
            color: #c6c6c6;
          }
          text-align: center;
          border: 1px solid #18A6F3;
          border-radius: 5px;
          box-sizing: border-box;
          &:nth-child(7n) {
            margin-right: 0;
          }
          &.none {
            border-color: #c6c6c6;
            color: #c6c6c6;
          }
          &.error {
            border-color: #ff790d;
            color: #333;
          }
        }
      }
    }
    .sheetBtn{
      padding: 0 0 43px;
      display: flex;
      justify-content: center;
      .van-button:last-child{
        margin-left: 20px;
        color: #1A7EE2;
        border: 1px solid #1A7EE2;
        background: #FFF;
      }
      .van-button{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        width: 120px;
        height: 40px;
        background: #F9F9F9;
        border-radius: 4px;
        border: none;
      }
    }
  }
</style>
